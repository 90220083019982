import { h } from 'preact';

export function Partner() {
  return (
    <section className="ntkd--section ntkd--partner" id="partner">
      <div className="container mx-auto">
        <div className="ntkd--content">
          <h2 className="ntkd--section--title">Partner</h2>

          <h3 className="text-lg lg:text-xl font-bold mb-4">Beteiligte Vereine</h3>
          <div className="grid grid-flow-row grid-cols-3 gap-4 mb-6">
            <PartnerLogo imageSrc={"dist/images/logo_budocenter_letzipark.png"} link={"https://www.budocenter-letzipark.ch/"} alt={"Budocenter Letzipark"}/>
            <PartnerLogo imageSrc={"dist/images/logo_grand_sports_academy_2.png"} link={"https://www.grandsports.ch/"} alt={"Grand Sports Academy"}/>
            <PartnerLogo imageSrc={"dist/images/logo_kim_tkd.png"} link={"https://www.taekwondo-winterthur.ch/"} alt={"Kim Taekwondo Winterthur"}/>
            <PartnerLogo imageSrc={"dist/images/logo_ntkd.png"} link={"https://www.noelstaekwondo.ch/"} alt={"Noël's Taekwondo"}/>
            <PartnerLogo imageSrc={"dist/images/logo_tcc_friedrichshafen.jpg"} link={"https://www.tcc-friedrichshafen.de/"} alt={"Taekwondo Competence Center Friedrichshafen"}/>
            <PartnerLogo imageSrc={"dist/images/logo_twist_tkd.png"} link={"https://twist-tkd.ch/"} alt={"Twist Taekwondo Zürich"}/>
          </div>

          <h3 className="text-lg lg:text-xl font-bold mb-4">Sportschulen</h3>
          <div className="grid grid-flow-row grid-cols-3 gap-4 mb-6">
            <PartnerLogo imageSrc={"dist/images/logo_ksgymnasium.svg"} link={"https://ksgymnasium.ch//"} alt={"Kunst und Sport Gymnasium - Rämibühl"}/>
            <PartnerLogo imageSrc={"dist/images/logo_sporttageschule_frauenfeld.png"} link={"https://www.schulen-frauenfeld.ch/p43007683.html"} alt={"Thurgauer Sporttagesschule Frauenfeld TSTF"}/>
            <PartnerLogo imageSrc={"dist/images/logo_sporttageschule_frauenfeld.png"} link={"https://www.schulen-frauenfeld.ch/p43011426.html"} alt={"Sporttagesschule Auen"}/>
            <PartnerLogo imageSrc={"dist/images/logo_united.png"} link={"https://www.unitedschool.ch/"} alt={"United School of Sports"}/>
          </div>

          <h3 className="text-lg lg:text-xl font-bold mb-4">Supporter</h3>
          <div className="grid grid-flow-row grid-cols-3 gap-4">
            <PartnerLogo imageSrc={"dist/images/logo_thurgau.svg"} link={"https://sportamt.tg.ch/"} alt={"Sportamt des Kantons Thurgau"}/>
            <PartnerLogo imageSrc={"dist/images/logo_swiss_olympic.svg"} link={"https://www.swissolympic.ch/"} alt={"Swiss Olympic"}/>
            <PartnerLogo imageSrc={"dist/images/logo_swiss_taekwondo.png"} link={"https://www.taekwondo.ch/"} alt={"Swiss Taekwondo"}/>
          </div>
        </div>
      </div>
    </section>
  )
}

export function PartnerLogo({ imageSrc, link = '/', alt= 'Logo', customClass = 'bg-white'}) {
  return (
    <a className={`flex justify-center items-center p-4 hover:bg-opacity-50 transition-opacity duration-200 ease-out shadow-sm ${customClass}`} href={link} target="_blank" title={alt}>
      <img className="mx-auto max-h-12" src={imageSrc} alt={alt}  />
    </a>
  );
}