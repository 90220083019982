import { h } from 'preact';

export function Purpose() {
  return (
    <section className="ntkd--section ntkd--purpose" id="purpose">
      <div className="container mx-auto">
        <div className="ntkd--content">
          <h2 className="ntkd--section--title">Sinn und Zweck des RLZO</h2>
          <p>Der Verband Regionales Leistungszentrum Ostschweiz Taekwondo (RLZO), ist ein Verein im Sinne von Art. 60ff. des Schweizerischen Zivilgesetzbuches (ZGB). Das RLZO versteht sich als Fachverband für Taekwondo in der Ostschweiz für folgende Disziplinen:</p>
          <ul>
            <li>Kyorugi (Kampf)</li>
            <li>Poomsae (Formenlauf)</li>
          </ul>
          <p>Das RLZO pflegt und fördert die Sportart Taekwondo, hauptsächlich im Raum Ostschweiz. Das RLZO arbeitet zu diesem Zweck mit dem Schweizerischen Taekwondo-Verband, mit verschiedenen Sportverbänden oder -Vereine sowie weiteren Organisationen zusammen. Das RLZO ist politisch unabhängig und konfessionell neutral.</p>
          <p>Ziel des RLZO ist es, spezifische und auf alle high-potential Athleten ausgerichtete Spezialtrainings anbieten zu können, so dass die Talente ihr Potenzial in den Trainings mit Gleichgesinnten voll ausschöpfen und sich zielgerichtet weiterentwickeln können.</p>
          <p>Für interessierte Taekwondo-Spitzenathleten besteht nach erfolgreichem Absolvieren eines Kadertests die Möglichkeit einer Mitgliedschaft.</p>
        </div>
      </div>
    </section>
  )
}