import { h } from 'preact';

export function Contact() {
  return (
    <section className="ntkd--section ntkd--contact" id="contact">
      <div className="container mx-auto">
        <div className="ntkd--content">
          <h2 className="ntkd--section--title">Kontakt</h2>
          <div className="ntkd--contact__box p-4 pt-8 lg:w-1/2">
            <h3 className="text-lg lg:text-xl font-bold mb-4">Kontaktdaten</h3>
            <p>RLZO Frauenfeld<br/>Bahnhofstrasse 57<br/>8500 Frauenfeld</p>
            <p>Matthias Gubler: <a href="mailto:praesident@rlzo.ch">praesident@rlzo.ch</a></p>
          </div>
        </div>
      </div>
    </section>
  )
}