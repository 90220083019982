import { h } from 'preact';

export function Hero() {
  return (
    <section className="ntkd--section ntkd--hero relative flex justify-center items-center h-screen lg:bg-fixed">
      <div className="container mx-auto">
        <div className="ntkd--content relative z-10">
          <h1 className="ntkd--maintitle text-3xl md:text-5xl text-white font-bold leading-normal uppercase tracking-wide text-center">
            <span className="text-blue">Taekwondo</span>
            <br/>
            Regionales Leistungszentrum
            <br/>
            Ostschweiz
          </h1>
        </div>
      </div>
    </section>
  )
}