import { h } from 'preact';

export function Footer() {
  const date = new Date();
  return (
    <footer className="ntkd--footer bg-dark text-sm text-medium">
      <div className="container flex justify-between items-center px-4 py-12 mx-auto">
        <div class="flex justify-start items-center opacity-50">
          <a className="block mr-2 hover:opacity-50" href="https://www.facebook.com/sharer/sharer.php?u=https://www.taekwondo-rlzo.ch/" title="Facebook" target="_blank"><img className="h-6" src="dist/images/facebook.png" /></a>
          <a className="block mr-2 hover:opacity-50" href="mailto:?subject=Taekwondo RLZO Frauenfeld&amp;body=Das RLZO versteht sich als Fachverband für Taekwondo in der Ostschweiz. Informiere dich jetzt: %0Ahttps://www.taekwondo-rlzo.ch/" title="E-Mail" target="_blank"><img className="h-8" src="dist/images/mail.png" /></a>
        </div>
        <div class="">
          © {date.getFullYear()} by taekwondo-rlzo.ch
        </div>
      </div>
    </footer>
  )
}