import { h } from 'preact';
import {useState} from "preact/hooks";

export function Navigation() {
  const [navOpen, setNavOpen] = useState(false);
  const [fixedHeader, setFixedHeader] = useState(false);

  function clickHandler() {
    if (navOpen) {
      setNavOpen(false);
    } else {
      setNavOpen(true);
    }
  }

  document.addEventListener('scroll', function(e){
    if (window.pageYOffset > 0) {
      setFixedHeader(true);
    } else {
      setFixedHeader(false);
    }
  });

  function onNaviClicked() {
    setNavOpen(false);
  }

  return (
    <header className={`ntkd--navigation fixed top-0 left-0 right-0 z-20 ${navOpen ? 'bg-black' : 'bg-transparent'} ${navOpen ? 'nav-open' : ''} ${fixedHeader ? 'fixed-header' : ''}`}>
      <div className="container flex justify-between items-center h-16 lg:h-24 mx-auto px-4">
        <a className="ntkd--logo block h-4/5" href="/">
          <img className="h-full opacity-0" src="dist/images/rlzo_logo_black.png" alt="RLZO Logo" />
        </a>
        <button class="mobile-nav relative w-8 h-8" onclick={clickHandler}>
          {navOpen
            ? (<div className="mobile-nav__close relative"/>)
            : (<div className="mobile-nav__burger relative h-1 bg-white rounded"/>)
          }
        </button>
      </div>
      {navOpen &&
        <div className="text-2xl text-white font-bold text-center pb-3">
          <a className="block hover:text-blue py-3" href="#purpose" onclick={onNaviClicked}>Sinn und Zweck des RLZO</a>
          <a className="block hover:text-blue py-3" href="#philosophy" onclick={onNaviClicked}>Philosophie</a>
          <a className="block hover:text-blue py-3" href="#location" onclick={onNaviClicked}>Unser Standort</a>
          <a className="block hover:text-blue py-3" href="#about-us" onclick={onNaviClicked}>Über uns</a>
          <a className="block hover:text-blue py-3" href="#funds" onclick={onNaviClicked}>Herkuft der Mittel des RLZO</a>
          <a className="block hover:text-blue py-3" href="#contact" onclick={onNaviClicked}>Kontakt</a>
          <a className="block hover:text-blue py-3" href="#partner" onclick={onNaviClicked}>Partner</a>
          <a className="block hover:text-blue py-3" href="#partner" onclick={onNaviClicked}>Sponsoring</a>
        </div>
      }
    </header>
  )
}