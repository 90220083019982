import { h } from 'preact';

export function Funds() {
  return (
    <section className="ntkd--section ntkd--funds" id="funds">
      <div className="container mx-auto">
        <div className="ntkd--content">
          <h2 className="ntkd--section--title">Herkunft der Mittel des RLZO</h2>
          <h3 className="text-xl font-bold mb-4">Woher nimmt das RLZO seine Mittel?</h3>
          <p>Das RLZO Frauenfeld wird als anerkannter Verband durch das <a href="https://sportamt.tg.ch/" target="_blank">Sportamt des Kantons Thurgau</a>, als auch von <a href="https://www.swissolympic.ch/" target="_blank">Swiss Olympic</a> finanziell unterstützt. Diese Gelder werden vollumfänglich zur Förderung der Athleten eingesetzt.</p>
          <p>Ebenfalls gehören Gönner und Sponsoren zu den Förderern des RLZO Frauenfeld.</p>
        </div>
      </div>
    </section>
  )
}