import { h } from 'preact';

export function Location() {
  return (
    <section className="ntkd--section ntkd--location"  id="location">
      <div className="container mx-auto">
        <div className="ntkd--content">
          <h2 className="ntkd--section--title">Unser Standort</h2>
          <div className="ntkd--location__maps">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2692.591888912034!2d8.891538051269684!3d47.556271179079054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479a9220112f0235%3A0x5f588c0aeb51c308!2sBahnhofstrasse%2057%2C%208500%20Frauenfeld!5e0!3m2!1sde!2sch!4v1613951919002!5m2!1sde!2sch" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
          </div>
        </div>
      </div>
    </section>
  )
}