import { h, Component, render } from 'preact';
import {Navigation} from "../../components/navigation";
import {Hero} from "../../components/hero";
import {Purpose} from "../../components/purpose";
import {Philosophy} from "../../components/philosophy";
import {Location} from "../../components/location";
import {AboutUs} from "../../components/aboutus";
import {Funds} from "../../components/funds";
import {Contact} from "../../components/contact";
import {Partner} from "../../components/partner";
import {Sponsoring} from "../../components/sponsoring";
import {Footer} from "../../components/footer";

class App extends Component {
  render() {
    return (
      <div>
        <Navigation />

        <Hero />

        <Purpose />

        <Philosophy />

        <Location />

        <ImageBreaker imageSrc="dojang.jpg" />

        <AboutUs />

        <Funds />

        <Contact />

        <Partner />

        <Sponsoring />

        <Footer />
      </div>
    );
  }
}

render(<App />, document.body);

function ImageBreaker({imageSrc}) {
  return (
    <div className="ntkd--image-breaker relative bg-cover bg-no-repeat bg-center lg:bg-fixed" style={`background-image: url("dist/images/${imageSrc}")`} />
  );
}