import { h } from 'preact';

export function Philosophy() {
  return (
    <section className="ntkd--section ntkd--philosophy" id="philosophy">
      <div className="container mx-auto">
        <div className="ntkd--content">
          <h2 className="ntkd--section--title">Philosophie</h2>
          <h3 className="text-lg lg:text-xl font-bold mb-4">Was leistet das RLZO Frauenfeld für Dich?</h3>
          <p>Kurz und bündig: Das RLZO Frauenfeld unterstützt leistungsbereite Athleten</p>
          <ul>
            <li>Physisch; durch gezielte Trainings mit starken Partnern</li>
            <li>Psychisch; durch ausgewählte Coaches und Erfolgserlebnissen</li>
            <li>Finanziell; durch Beteiligung an Wettkampf- und Reisekosten</li>
          </ul>

          <h3 className="text-lg lg:text-xl font-bold mb-4">Was musst Du tun?</h3>
          <p>Deine Gegenleistungen:</p>
          <ul>
            <li>Du bist Mitglied in einem Taekwondo-Verein, gehörst zum Kader und willst weiterkommen?</li>
            <li>Du bist extrem Leistungsbereit und bringst absoluten Einsatz – sowohl in den Trainings und als auch in Wettkämpfen?</li>
          </ul>
          <p>Dann melde Dich bei uns und/oder Deinem Trainer und schnuppere bei uns rein.</p>
          <hr />
          <p>Für eine Aufnahme ins RLZO Frauenfeld musst Du zu folgendem bereit sein:</p>
          <ul>
            <li>Du bestreitest mit dem RLZO Frauenfeld ausgewählte internationale Wettkämpfe</li>
            <li>Du unterstützt das RLZO mit deiner Leistung, Motivation und Präsenz – in Trainings und Wettkämpfen als auch durch online Statements auf unseren Kanälen</li>
            <li>Du befolgst die RLZO-Regeln und Weisungen</li>
            <li>Du verhaltest Dich vorbildlich, in sportlichen als auch privaten Angelegenheiten</li>
          </ul>
        </div>
      </div>
    </section>
  )
}