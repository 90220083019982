import { h } from 'preact';

export function AboutUs() {
  return (
    <section className="ntkd--section ntkd--aboutus"  id="about-us">
      <div className="container mx-auto">
        <div className="ntkd--content">
          <h2 className="ntkd--section--title">Über uns</h2>
          <p className="font-bold">Coaches</p>
          <div className="lg:text-lg mb-1">Kyorugi</div>
          <ul>
            <li>
              Noel Kurzen - Trainer<br/>
              4. Dan,<br/>
              Swiss Olympic Konditionstrainer,<br/>
              Trainer Leistungssport mit eidg. Fachausweis
            </li>
          </ul>
          <div className="lg:text-lg mb-1">Poomsae</div>
          <ul>
            <li>
              Dieu Song<br/>
              7. Dan,<br/>
              Zertifizierter Internationaler Taekwondo Trainer (WT),<br/>
              Sportausbildung Fachhochschule Westschweiz,<br/>
              Internationaler Poomsae Punkterichter,
            </li>
            <li>
              Bunni Khun<br/>
              3. Dan,<br/>
              Zertifizierter Nationaler Taekwondo Instruktor
            </li>
          </ul>
          <p className="font-bold ">Vorstand</p>
          <img className="mb-10" src="dist/images/organigramm.png" alt="Organigramm" />
          <p className="font-bold">Teilnehmende Vereine</p>
          <ul>
            <li>Budocenter Letzipark - Zürich</li>
            <li>Grand Sports Academy - Winterthur</li>
            <li>Kim TKD Winterthur - Winterthur</li>
            <li>Noël's Taekwondo - Frauenfeld, Turbenthal, Pfäffikon ZH</li>
            <li>Twist Taekwondo - Zürich</li>
          </ul>
          <p className="font-bold">Athleten</p>
          <div className="lg:text-lg mb-1">Senior</div>
          <ul>
            <li>Andrea Schnell (A) – Noël's TKD</li>
            <li>Lorena Ortiz (A) – Twist TKD Zürich</li>
            <li>Miccoli Tatjana (A) - Grand Sports Academy</li>
            <li>Esatullah Haidari (B) – Grand Sports Academy</li>
            <li>Timotej Mlinaric (B) - Twist TKD Zürich</li>
          </ul>
          <div className="lg:text-lg mb-1">Junior</div>
          <ul>
            <li>Kinnari Brack (A) – Twist TKD Zürich</li>
            <li>Kyra Kehrli (A)– Noël's TKD</li>
            <li>Leonardo Mair (A) – Noël's TKD</li>
          </ul>
          <div className="lg:text-lg mb-1">Cadet</div>
          <ul>
            <li>Ajla Alija (B) – Noël's TKD</li>
            <li>Arlind Bekiri (B) – Noël's TKD</li>
          </ul>
        </div>
      </div>
    </section>
  )
}