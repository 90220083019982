import { h } from 'preact';
import {PartnerLogo} from "../partner";

export function Sponsoring() {
  return (
    <section className="ntkd--section ntkd--sponsoring" id="sponsoring">
      <div className="container mx-auto">
        <div className="ntkd--content">
          <h2 className="ntkd--section--title">Sponsoring</h2>
          <div className="grid grid-flow-row grid-cols-3 gap-4 mb-6">
            <div />
            <PartnerLogo imageSrc={"dist/images/logo_kwon_black.png"} link={"https://www.kwon.ch/"} alt={"Kwon"} customClass="bg-light"/>
            <div />
          </div>
        </div>
      </div>
    </section>
  )
}